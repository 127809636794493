<template>
  <div
    class="mujeeb-container z-10 fixed flex justify-center items-center content-center top-0 bottom-0 right-0 left-0 bg-transparant-black"
  >
    <div class="rounded bg-white">
      <div class="p-4 text-black rounded">
        <p class="rtl m-3 font-bold text-xl mb-5">
          <font-awesome-icon icon="exclamation-triangle" />{{ deletionMessage }}
        </p>
      </div>
      <div class="flex justify-end pl-4 pr-4 pb-4">
        <button
          class="mujeeb-button big green"
          @click="
            whatToDelete != null ? $emit('deletionConfirmed') : $emit('toggleReply', 'approve')
          "
        >
          نعم
        </button>
        <button
          class="mujeeb-button big"
          @click="whatToDelete != null ? $emit('close') : $emit('toggleReply', 'discard')"
        >
          كلا
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirmation_modal",
  props: {
    whatToDelete: String
  },
  data() {
    return {};
  },
  computed: {
    deletionMessage() {
      switch (this.whatToDelete) {
        case "custom":
          return "هل أنت متاكد من رغبتك بحذف هذه الإجابة المخصصة؟";
        case "default":
          return "هل أنت متأكد من رغبت بحذف هذالإجابة العامة؟";
        case "reply":
          return "سيتم حذف هذه الإجابة بالكامل، هل أنت متاكد من ذلك؟";
        default:
          return "هل أنت متاكد من رغبتك بتغيير حالة هذه الإجابة؟";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mujeeb-container {
  > div {
    min-width: 300px;
  }
  hr {
    height: 1px !important;
    background-color: rgb(233, 233, 233);
    margin: 30px 0px;
  }
  .icon {
    margin: 0px;
    margin-left: 10px;
    font-size: 1.875rem;
  }
}
</style>
