<template>
  <div>
    <confirmationModal
      v-if="showConfirmationModal"
      :whatToDelete="whatToDelete"
      @deletionConfirmed="deletionConfirmed"
      @toggleReply="onToggleConfirmed"
      @close="resetState"
    >
    </confirmationModal>

    <newCustomReplyModal
      v-if="showReplyModal"
      :reply_prop="stagedIntent"
      :modal="true"
      :reply_type_prop="inputReplyType"
      @close="closeReplyModal"
      @SUBMIT_REPLY="submitReply($event)"
    >
    </newCustomReplyModal>

    <StoppAllRepliersModal
      v-if="showStoppingModal"
      @close="showStoppingModal = false"
      @confirm="onStopAllRepliers"
    >
    </StoppAllRepliersModal>

    <div v-if="!loading && replies.length > 0">
      <div class="flex content-center items-center text-right rtl">
        <button class="mujeeb-button red big" @click="confirmStopAllRepliers">
          إيقاف الإجابات قبل تاريخ معين
        </button>
      </div>
      <div class="m-table rtl">
        <div class="row i-7 m-table-header">
          <div class="item i-7 flex items-center content-center">صورة</div>
          <div class="item i-7 flex items-center content-center">إجابة مخصصة</div>
          <div class="item i-7 flex items-center content-center">إجابة عامة</div>
          <div class="item i-7 flex items-center content-center">تاريخ التفعيل</div>
          <div class="item i-7 flex items-center content-center">
            <font-awesome-icon icon="external-link-alt" /> رابط المنشور
          </div>
          <div class="item i-7 flex items-center content-center small">
            <font-awesome-icon icon="stop-circle" /> إيقاف
          </div>
          <div class="item i-7 flex items-center content-center small">
            <font-awesome-icon icon="trash-alt" />حذف
          </div>
        </div>

        <div class="row i-7" v-for="(reply, replyIndex) in replies" :key="reply.date">
          <div class="item">
            <VLazyImage
              class="post-image"
              v-if="reply.postContent.image != null"
              :src="reply.postContent.image"
            ></VLazyImage>
            <div v-else>
              <p class="text-center text-3xl lg:text-lg md:text-3xl sm:text-3xl">
                {{ reply.postContent.message | shortning }}
              </p>
            </div>
          </div>

          <div class="item" data="الإجابات المخصصة">
            <div class="mobile-view">
              <div
                v-for="(customReply, index) in reply.intents.customIntents"
                :key="index"
                class="flex m-2"
              >
                <span
                  class="reply-tooltip__close-button cursor-pointer flex items-center justify-center content-center bg-white text-mujeeb-red border border-mujeeb-red p-2 text-white"
                  @click="deleteIntent('custom', reply, index)"
                >
                  <font-awesome-icon icon="times" />
                </span>
                <span
                  @click="initStagedReply_edit('custom', reply, index)"
                  class="reply-tooltip border p-2 flex items-center content-center whitespace-no-wrap cursor-pointer"
                >
                  {{ keyWordsSummary(customReply.replyTo) }}
                </span>
              </div>
              <button
                class="m-2 mobile-view-button font-bold bg-mujeeb-green text-white rounded flex justify-center items-center content-center"
                style="padding: 0.5rem; font-size: 16px;"
                @click="initStagedReply_addition('custom', reply, replyIndex)"
              >
                إضافة إجابة مخصصة
                <font-awesome-icon icon="plus" />
              </button>
            </div>

            <div class="desktop-view relative flex justify-start">
              <button
                class="border border-1 text-mujeeb-blue border-mujeeb-blue red only-icon h-12 w-12 rounded-full ml-2"
                :class="{ 'tooltip-trigger': index_of_clickedOn_custom_reply === replyIndex }"
                v-if="reply.intents.customIntents && reply.intents.customIntents.length > 0"
                @click="custom_onSummaryClicked(replyIndex)"
              >
                <font-awesome-icon icon="pen" />
              </button>

              <div
                class="replies-tooltip absolute shadow-xl bg-white p-2 border border-1 border-gray-100 rounded"
                v-if="index_of_clickedOn_custom_reply === replyIndex"
              >
                <div
                  v-for="(customReply, index) in reply.intents.customIntents"
                  :key="index"
                  class="flex mb-2"
                >
                  <span
                    class="reply-tooltip__close-button cursor-pointer flex items-center justify-center content-center bg-white text-mujeeb-red border border-mujeeb-red p-2 text-white"
                    @click="deleteIntent('custom', reply, index)"
                  >
                    <font-awesome-icon icon="times" />
                  </span>
                  <span
                    @click="initStagedReply_edit('custom', reply, index)"
                    class="reply-tooltip border border-gray-400 p-2 flex items-center content-center whitespace-no-wrap cursor-pointer"
                  >
                    {{ keyWordsSummary(customReply.replyTo) }}
                  </span>
                </div>
              </div>

              <button
                class="border border-1 text-mujeeb-green border-mujeeb-green only-icon h-12 w-12 rounded-full"
                @click="initStagedReply_addition('custom', reply, replyIndex)"
              >
                <font-awesome-icon icon="plus" />
              </button>
            </div>
          </div>

          <div class="item" data="الإجابة العامة">
            <div v-if="reply.intents.default">
              <button
                class="mobile-view-button font-bold border border-1 bg-white text-mujeeb-blue only-icon h-12 w-12 rounded-full"
                @click="initStagedReply_edit('default', reply, replyIndex)"
                data="تعديل الإجابة العامة"
              >
                <font-awesome-icon icon="pen" />
              </button>
            </div>

            <button
              v-else
              class="mobile-view-button font-bold border border-1 bg-white text-mujeeb-green only-icon h-12 w-12 rounded-full"
              data="إضافة إجابة عامة"
              @click="initStagedReply_addition('default', reply, replyIndex)"
            >
              <font-awesome-icon icon="plus" />
            </button>
          </div>

          <div class="item" data="تاريخ التفعيل">
            {{ reply.date | makeItReadable }}
          </div>

          <div class="item" data="رابط المنشور">
            <a
              :href="reply.postUrl"
              target="_blanck"
              class="border border-1 text-mujeeb-blue rounded-full h-12 w-12 border-mujeeb-blue only-icon flex items-center content-center justify-center"
              ><font-awesome-icon icon="link" />
            </a>
          </div>

          <div class="item small switch" data="تفعيل">
            <b-switch class="ltr" v-model="reply.enabled" @input="toggle(reply, $event)"></b-switch>
          </div>

          <div class="item small control">
            <button
              class="border border-1 border-red-500 text-red-500 only-icon h-12 w-12 rounded-full"
              @click="deleteIntent('reply', reply, replyIndex)"
              data="حذف"
            >
              <font-awesome-icon icon="trash-alt" />
            </button>
          </div>
        </div>
      </div>

      <div class="flex justify-center mt-16 mb-20">
        <button v-if="canPagingForward" @click="next" class="mujeeb-button big green icon-on-right">
          <font-awesome-icon icon="chevron-left" /> التالي
        </button>
        <button v-if="canPagingBackward" @click="back" class="mujeeb-button big green icon-on-left">
          السابق<font-awesome-icon icon="chevron-right" />
        </button>
      </div>
    </div>

    <div
      v-else-if="!loading && replies.length === 0"
      style="border: 1px dashed black; border-radius: 6px;"
    >
      <p class="empty-table-msg">لم يتم إدخال إجابات تلقائية</p>
    </div>

    <loading-spinner v-else-if="loading" position="absolute"></loading-spinner>
  </div>
</template>

<script>
import moment from "moment";
import confirmationModal from "./utils/confirationModal";
import newCustomReplyModal from "./utils/customReplyModal_new";
import VLazyImage from "v-lazy-image";
import StoppAllRepliersModal from "./utils/stopAllRepliers";

export default {
  name: "REPLY_BROWSER",
  components: {
    confirmationModal,
    newCustomReplyModal,
    VLazyImage,
    StoppAllRepliersModal
  },
  data() {
    return {
      // --------------------------------
      numberOfCharsInSummary: 15,
      limitTo: 10,
      replies: [],
      loading: false,
      // pagination
      next_referenceValue: null,
      back_referenceValue: null,
      old_next_referenceValue: null,
      old_back_referenceValue: null,
      old_action: null,
      canPagingForward: true,
      canPagingBackward: false,
      stayOnSamePage: false,
      // modals
      stagedReply: null,
      // tooltip
      index_of_clickedOn_custom_reply: null,
      showConfirmationModal: false,
      whatToDelete: null,
      staged_reply_index: null,
      // new
      stagedIntent: null,
      inputReplyType: null,
      showReplyModal: false,
      // stop all repliers
      showStoppingModal: false
    };
  },
  methods: {
    confirmStopAllRepliers() {
      /**
       * show confirmation modal
       * if yes
       * call the api
       * if no
       * ignore
       */
      this.showStoppingModal = true;
    },
    onStopAllRepliers(beforeDate) {
      this.showStoppingModal = false;
      console.log(beforeDate);
      this.loading = true;
      this.$api
        .customRequest({
          method: "put",
          url: `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/replies/stop-all`,
          params: {
            before: beforeDate
          }
        })
        .then(({ data }) => {
          console.log(data);
        })
        .then(() => {
          return this.fetchPosts();
        })
        .then(() => {
          this.resetState();
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // padination
    next() {
      this.fetchPosts("next");
      this.old_next_referenceValue = this.next_referenceValue;
      this.canPagingBackward = true;
      this.old_action = "next";
    },
    back() {
      this.fetchPosts("back");
      this.old_back_referenceValue = this.back_referenceValue;
      this.canPagingForward = true;
      this.old_action = "back";
    },
    fetchPosts(action = "next", reference) {
      this.loading = true;
      return this.$api
        .customRequest({
          url: `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/replies`,
          method: "get",
          params: {
            action: action,
            referenceValue: reference
              ? reference
              : this.stayOnSamePage
              ? this.old_action === "next"
                ? this.old_next_referenceValue
                : this.old_back_referenceValue
              : action === "next"
              ? this.next_referenceValue
              : this.back_referenceValue
          },
          responseType: "json"
        })
        .then(({ data }) => {
          if (data.replies && data.replies.length > 0) {
            this.canPagingForward = data.canPagingforward;
            this.canPagingBackward = data.canPagingBackward;
            const thereIsStillPosts = data.replies.length === this.limitTo + 1;
            console.log(data.replies);
            if (thereIsStillPosts) {
              this.replies = data.replies.slice(0, this.limitTo);
              this.next_referenceValue = data.replies[this.limitTo].date;
            } else {
              this.replies = data.replies;
              this.next_referenceValue = null;
            }
            this.back_referenceValue = data.replies[0].date;
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // for both
    deleteIntent(type, _reply, index) {
      // show deletion modal
      const customIntents = this.$getAtPath(["intents", "customIntents"], _reply);
      const defaultIntent = this.$getAtPath(["intents", "default"], _reply);
      this.stagedReply = _reply;
      const IndexInRepliesArray = this.replies.findIndex(reply => reply.postId === _reply.postId);

      this.staged_reply_index = type === "custom" ? index : IndexInRepliesArray;

      this.whatToDelete =
        type === "custom"
          ? defaultIntent != null
            ? type
            : customIntents.length > 1
            ? type
            : "reply"
          : type === "default"
          ? customIntents != null && customIntents.length >= 1
            ? type
            : "reply"
          : "reply";
      this.showConfirmationModal = true;
    },
    deletionConfirmed() {
      this.showConfirmationModal = false;
      this.stayOnSamePage = true;
      let promise = null;
      switch (this.whatToDelete) {
        case "default":
          this.$delete(this.stagedReply.intents, "default");
          promise = this.fireUpdateReplyRequest(this.stagedReply, "put");
          break;
        case "custom":
          this.$delete(this.stagedReply.intents.customIntents, this.staged_reply_index);
          promise = this.fireUpdateReplyRequest(this.stagedReply, "put");
          break;
        case "reply":
          this.$delete(this.replies, this.staged_reply_index);
          promise = this.fireUpdateReplyRequest(this.stagedReply, "delete");
          break;
      }
      return promise
        .then(() => {
          return this.fetchPosts();
        })
        .then(() => {
          this.resetState();
        })
        .catch(err => {
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء حذف الإجابة",
            position: "is-top",
            type: "is-danger"
          });
        });
    },
    initStagedReply_addition(type, _reply, index) {
      this.stagedReply = _reply;
      this.staged_reply_index = index;

      this.inputReplyType = type;
      this.showReplyModal = true;
    },
    initStagedReply_edit(type, _reply, index) {
      this.stagedReply = _reply;
      this.staged_reply_index = index;
      if (type === "custom") {
        this.stagedIntent = _reply.intents.customIntents[index];
        this.inputReplyType = "custom";
      } else if (type === "default") {
        this.stagedIntent = _reply.intents.default;
        this.inputReplyType = "default";
      }
      this.showReplyModal = true;
    },
    toggle(reply, value) {
      this.resetState();
      this.stagedReply = reply;
      this.showConfirmationModal = true;
    },
    onToggleConfirmed(state) {
      this.stayOnSamePage = true;
      this.showConfirmationModal = false;
      if (state === "approve") {
        this.loading = true;
        this.fireUpdateReplyRequest(this.stagedReply, "put")
          .then(() => {
            return this.fetchPosts();
          })
          .then(() => {
            this.resetState();
          })
          .catch(err => {
            console.log(err);
            // show an error message
          });
      } else {
        this.stagedReply.enabled = !this.stagedReply.enabled;
        this.resetState();
      }
    },
    // customReply
    custom_onSummaryClicked(index) {
      if (
        this.index_of_clickedOn_custom_reply != null &&
        index !== this.index_of_clickedOn_custom_reply
      ) {
        this.index_of_clickedOn_custom_reply = index;
      } else if (this.index_of_clickedOn_custom_reply != null) {
        this.index_of_clickedOn_custom_reply = null;
      } else {
        this.index_of_clickedOn_custom_reply = index;
      }
    },
    keyWordsSummary(keyWords) {
      return keyWords.length > 1
        ? keyWords.join(", ").substring(0, this.numberOfCharsInSummary) + "..."
        : keyWords.join(", ");
    },
    fireUpdateReplyRequest(reply, method = "put") {
      this.loading = true;
      return this.$api
        .customRequest({
          method,
          url: `/services/reply/users/${this.$store.getters["user/id"]}/pages/${this.$store.getters["page/id"]}/replies/${reply.postId}`,
          data: {
            reply
          },
          responseType: "json"
        })
        .then(({ data }) => {
          this.loading = false;
        })
        .catch(err => {
          console.log(err.response.data);
        });
    },
    // END OF THE REPLIES METHODS
    isVisible(elem) {
      const res =
        !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);
      return res;
    },
    clickOutSideTootlTipTrigger(event) {
      const repliesTooltip = document.getElementsByClassName("replies-tooltip")[0];
      const replyTrigger = document.querySelectorAll(".tooltip-trigger")[0];
      if (
        this.isVisible(repliesTooltip) &&
        !repliesTooltip.contains(event.target) &&
        this.isVisible(replyTrigger) &&
        !replyTrigger.contains(event.target)
      ) {
        // clicking outside the subnav menu
        this.index_of_clickedOn_custom_reply = null;
      }
    },
    resetState() {
      this.staged_reply_index = null;
      this.stagedReply = null;
      this.whatToDelete = null;
      this.showConfirmationModal = false;
      this.stayOnSamePage = false;

      this.showReplyModal = false;
      this.stagedIntent = null;
      this.inputReplyType = null;
    },
    // NEW
    closeReplyModal() {
      this.stagedReply = null;
      this.staged_reply_index = null;
      this.showReplyModal = false;
      this.stagedIntent = null;
      this.inputReplyType = null;
    },
    submitReply(payload) {
      if (payload.action === "edit") {
        this.stayOnSamePage = true;
        this.showReplyModal = false;
        if (this.inputReplyType === "default") {
          this.stagedReply.intents.default = payload.data;
        } else if (this.inputReplyType === "custom") {
          this.stagedReply.intents.customIntents[this.staged_reply_index] = payload.data;
        }
        return this.fireUpdateReplyRequest(this.stagedReply)
          .then(() => {
            return this.fetchPosts();
          })
          .finally(() => {
            this.resetState();
          });
      } else if (payload.action === "new") {
        this.stayOnSamePage = true;
        this.showReplyModal = false;
        if (this.inputReplyType === "default") {
          this.stagedReply.intents.default = payload.data;
        } else if (this.inputReplyType === "custom") {
          if (this.stagedReply.intents.customIntents) {
            this.stagedReply.intents.customIntents.push(payload.data);
          } else {
            this.stagedReply.intents.customIntents = [payload.data];
          }
        }
        return this.fireUpdateReplyRequest(this.stagedReply)
          .then(() => {
            return this.fetchPosts();
          })
          .finally(() => {
            this.resetState();
          });
      }
    }
  },
  created() {
    this.fetchPosts();
  },
  filters: {
    makeItReadable: function(value) {
      if (value) {
        return moment(value)
          .utc()
          .format("H:m-DD/MM/YYYY");
      }
    },
    shortning: function(value) {
      if (!value) return "";
      value = value.toString();
      return `" ${value.substring(0, 200)} "`;
    }
  },
  mounted() {
    document.addEventListener("click", this.clickOutSideTootlTipTrigger);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickOutSideTootlTipTrigger);
  }
};
</script>

<style lang="scss" scoped>
// button {
//   svg {
//     margin-right: 10px;
//   }
// }

.m-table {
  width: 100%;
  display: block;
  margin-top: 30px;
  .row {
    display: flex;
    align-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 6px;
    padding: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
    margin: 20px;
    &.m-table-header {
      margin-bottom: 20px;
      font-weight: bold;
      box-shadow: none;
      .item {
        svg {
          margin-left: 5px;
          font-size: 12px;
        }
        &.gray-text {
          color: rgba(194, 194, 194, 0.555);
        }
      }
    }
    &.i-6 {
      .item {
        flex: 1 0 16.66%;
        .mobile-view {
          display: none;
        }
        .post-image {
          height: 50px;
          width: 100px;
          object-fit: scale-down;
        }
        &.small {
          flex-basis: 50px;
          text-align: left;
          display: flex;
          justify-content: center;
        }
        .icon,
        .fas,
        .far {
          font-size: 20px;
        }
        .only-icon {
          .icon {
            padding: 0px;
            margin: 0px;
          }
        }
      }
    }
    &.i-7 {
      .item {
        flex: 1 0 14.2%;
        .mobile-view {
          display: none;
        }
        .post-image {
          height: 50px;
          width: 100px;
          object-fit: scale-down;
        }
        &.small {
          flex-basis: 50px;
          text-align: left;
          display: flex;
          justify-content: center;
        }
        .icon,
        .fas,
        .far {
          font-size: 20px;
        }
        .only-icon {
          .icon {
            padding: 0px;
            margin: 0px;
          }
        }
      }
    }
  }
}

.replies-tooltip {
  top: 50%;
  right: 120px;
  transform: translateY(-50%);
  font-size: 15px;
  z-index: 4;
  > div:last-child {
    margin: 0px;
  }
  p {
    white-space: nowrap;
  }
}

.reply-tooltip {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border: 1px solid #9d9b9b;
  border-right: none;
  background: #9d9b9b;
  color: white;
}
.reply-tooltip__close-button {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

@media only screen and (max-width: 1450px) {
  .m-table {
    .row {
      margin: 20px 0px !important;
      padding: 15px !important;
      display: block !important;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.219), 0 2px 4px 0 rgba(0, 0, 0, 0.08) !important;
      .item {
        display: block;
        margin-bottom: 20px;
        &::before {
          font-family: cairo;
          font-weight: 900;
          font-size: 16px;
          color: #000000;
          line-height: 1.2;
          margin-bottom: 6px;
          content: attr(data);
          min-width: 98px;
          display: block;
          margin-bottom: 15px;
        }
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background: rgba(177, 177, 177, 0.445);
          margin: 10px 0px;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        &.gray-text {
          color: rgba(194, 194, 194, 0.555);
        }
        &.small {
          display: block !important;
          text-align: right !important;
          &.control {
            display: flex !important;
            justify-content: center !important;
            align-content: center !important;
            align-items: center !important;
            button {
              display: flex;
              justify-content: center;
              align-items: center;
              align-content: center;
              border-radius: 0.25rem;
              width: 100px;
              background: $danger;
              color: white;
              &::before {
                display: none;
              }
              &::after {
                content: attr(data);
                margin-right: 10px;
              }
              svg {
                margin: 10px !important;
              }
            }
            &::before {
              display: none;
            }
          }
        }
        a {
          margin-top: 15px;
        }
        .desktop-view {
          display: none;
        }
        .mobile-view {
          display: flex !important;
          flex-wrap: wrap;
        }
        button {
          &.mobile-view-button {
            width: auto;
            height: auto;
            display: flex;
            justify-content: space-evenly;
            align-content: center;
            align-items: center;
            border-radius: 0.25rem;
            padding: 0.5rem;
            background: $success;
            color: white;
            &::before {
              content: attr(data);
              display: block;
            }
            svg {
              margin-right: 10px;
            }
          }
        }
        img {
          &.post-image {
            max-height: 500px !important;
            height: 500px !important;
            width: 100% !important;
            object-fit: scale-down !important;
          }
        }
      }
    }
    .m-table-header {
      display: none !important;
      color: red;
    }
  }
}
</style>
