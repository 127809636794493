<template>
  <div
    class="overflow-y-auto fixed top-0 right-0 bottom-0 left-0 w-full h-full flex justify-center align-center items-center bg-transparant-black z-10"
  >
    <div
      class="reply-input-modal text-right block reply-modal rounded-sm shadow bg-white p-8 small:w-5/6 md:w-11/12 overflow-y-auto m-auto"
    >
      <p class="text-center mb-12 font-bold text-xl">
        إيقاف كافة الإجابات التلقائية قبل تاريخ معين
      </p>

      <div class="mb-8">
        <div class="m-field-label text-right rtl">
          <label class="label rtl"> اختر تاريخاً لإيقاف كل الإجابات <strong>قبله</strong></label>
        </div>
        <div class="m-field-input flex justify-center content-center items-center">
          <b-datepicker
            inline
            v-model="beforeDate"
            @input="dateChanged"
            placeholder="اختر تاريخا..."
          >
          </b-datepicker>
        </div>
      </div>

      <div class="m-field-label text-right rtl">
        <label class="label rtl">
          لإيقاف كافة الإجابات يرجى إدخال العبارة التالية:
          <p><strong> إيقاف جميع الإجابات </strong></p>
        </label>
      </div>
      <div class="m-field-input">
        <b-input v-model.trim="confirmationSentence" class="rtl"></b-input>
      </div>
      <div class="mt-4 flex justify-center items-center content-center">
        <button class="mujeeb-button green big" @click="stopAllRepliers">إيقاف</button>
        <button class="mujeeb-button big" @click="$emit('close')">إغلاق</button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      confirmationSentence: null,
      canStop: false,
      beforeDate: new Date(),
      beforeDate_milli: moment()
        .utc()
        .valueOf()
    };
  },
  methods: {
    dateChanged(value) {
      this.beforeDate = value;
      this.beforeDate_milli = moment(value)
        .utc()
        .valueOf();
    },
    stopAllRepliers() {
      if (!this.canStop) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "العبارة غير مطابقة",
          position: "is-top",
          type: "is-danger"
        });
      } else {
        this.$emit("confirm", this.beforeDate_milli);
      }
    }
  },
  watch: {
    confirmationSentence: function(newVal) {
      if (this.confirmationSentence === "إيقاف جميع الإجابات") {
        this.canStop = true;
      } else {
        this.canStop = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
